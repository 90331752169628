import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Img = styled.img`
  border-radius: 100%;
`;
